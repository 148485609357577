import React from "react";
import styled from "styled-components";

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  :hover {
    text-decoration: underline;
  }
`;

const OnlineVhsLogo = props => (
  <Link href="https://www.onlinevhs.net" {...props}>
    <svg
      height="30"
      viewBox="0 0 805 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M232.7 116C232.7 106.4 235.4 99.3 240.7 94.7C245.2 90.9 250.6 88.9 257 88.9C264.1 88.9 270 91.2 274.5 95.9C279 100.6 281.3 107 281.3 115.3C281.3 122 280.3 127.2 278.3 131C276.3 134.8 273.4 137.8 269.6 139.9C265.8 142 261.6 143.1 257.1 143.1C249.8 143.1 244 140.8 239.5 136.1C234.9 131.5 232.7 124.8 232.7 116ZM241.7 116C241.7 122.6 243.1 127.6 246 130.9C248.9 134.2 252.5 135.9 256.9 135.9C261.3 135.9 264.9 134.2 267.8 130.9C270.7 127.6 272.1 122.5 272.1 115.7C272.1 109.3 270.6 104.4 267.7 101.1C264.8 97.8 261.2 96.1 256.8 96.1C252.4 96.1 248.8 97.7 245.9 101C243.1 104.4 241.7 109.4 241.7 116V116Z"
        fill="#1E3057"
      />
      <path
        d="M289 142V90.1H296.9V97.5C300.7 91.8 306.2 89 313.4 89C316.5 89 319.4 89.6 322 90.7C324.6 91.8 326.6 93.3 327.9 95.1C329.2 96.9 330.1 99.1 330.6 101.6C330.9 103.2 331.1 106.1 331.1 110.1V142H322.3V110.5C322.3 106.9 322 104.2 321.3 102.5C320.6 100.7 319.4 99.3 317.7 98.3C316 97.2 313.9 96.7 311.6 96.7C307.9 96.7 304.6 97.9 301.9 100.3C299.2 102.7 297.8 107.2 297.8 113.8V142H289Z"
        fill="#1E3057"
      />
      <path d="M342 142V70.4H350.8V142H342Z" fill="#1E3057" />
      <path
        d="M361.9 80.5V70.4H370.7V80.5H361.9ZM361.9 142V90.1H370.7V142H361.9Z"
        fill="#1E3057"
      />
      <path
        d="M381.601 142V90.1H389.501V97.5C393.301 91.8 398.801 89 406.001 89C409.101 89 412.001 89.6 414.601 90.7C417.201 91.8 419.201 93.3 420.501 95.1C421.801 96.9 422.701 99.1 423.201 101.6C423.501 103.2 423.701 106.1 423.701 110.1V142H414.901V110.5C414.901 106.9 414.601 104.2 413.901 102.5C413.201 100.7 412.001 99.3 410.301 98.3C408.601 97.2 406.501 96.7 404.201 96.7C400.501 96.7 397.201 97.9 394.501 100.3C391.801 102.7 390.401 107.2 390.401 113.8V142H381.601Z"
        fill="#1E3057"
      />
      <path
        d="M470.2 125.3L479.3 126.4C477.9 131.7 475.2 135.8 471.3 138.8C467.4 141.7 462.5 143.2 456.5 143.2C448.9 143.2 442.9 140.9 438.5 136.2C434.1 131.5 431.8 125 431.8 116.5C431.8 107.8 434 101 438.5 96.2C443 91.4 448.8 89 456 89C462.9 89 468.6 91.4 473 96.1C477.4 100.8 479.6 107.5 479.6 116V118.3H440.9C441.2 124 442.8 128.4 445.7 131.4C448.6 134.4 452.2 135.9 456.5 135.9C459.7 135.9 462.5 135.1 464.8 133.4C467.1 131.7 468.9 129 470.2 125.3V125.3ZM441.3 111.1H470.3C469.9 106.7 468.8 103.5 467 101.3C464.2 97.9 460.6 96.2 456.1 96.2C452.1 96.2 448.7 97.6 445.9 100.3C443.2 102.9 441.6 106.5 441.3 111.1Z"
        fill="#1E3057"
      />
      <path
        d="M502.7 142L481.8 90.1H496.2L506 116.6L508.8 125.4C509.5 123.2 510 121.7 510.2 121C510.7 119.5 511.1 118.1 511.7 116.6L521.6 90.1H535.7L515 142H502.7Z"
        fill="#1E3057"
      />
      <path
        d="M555.2 70.4V96.7C559.6 91.5 564.9 88.9 571.1 88.9C574.3 88.9 577.1 89.5 579.6 90.7C582.1 91.9 584.1 93.4 585.3 95.2C586.6 97 587.5 99 587.9 101.3C588.4 103.5 588.6 106.9 588.6 111.6V142H575V114.6C575 109.2 574.7 105.7 574.2 104.2C573.7 102.7 572.8 101.6 571.4 100.7C570.1 99.8 568.4 99.4 566.4 99.4C564.1 99.4 562.1 100 560.3 101.1C558.5 102.2 557.2 103.9 556.4 106.1C555.6 108.3 555.2 111.6 555.2 116V142H541.5V70.4H555.2Z"
        fill="#1E3057"
      />
      <path
        d="M595.3 127.2L609.1 125.1C609.7 127.8 610.9 129.8 612.7 131.2C614.5 132.6 617 133.3 620.2 133.3C623.7 133.3 626.4 132.6 628.2 131.3C629.4 130.4 630 129.2 630 127.6C630 126.6 629.7 125.7 629 125C628.3 124.3 626.8 123.7 624.4 123.2C613.3 120.8 606.3 118.5 603.4 116.5C599.3 113.7 597.2 109.8 597.2 104.8C597.2 100.3 599 96.5 602.5 93.5C606 90.4 611.5 88.9 619 88.9C626.1 88.9 631.4 90.1 634.8 92.4C638.3 94.7 640.6 98.1 641.9 102.7L629 105C628.4 103 627.4 101.4 625.9 100.4C624.4 99.3 622.1 98.8 619.3 98.8C615.7 98.8 613.1 99.3 611.5 100.3C610.5 101 609.9 101.9 609.9 103.1C609.9 104.1 610.4 104.9 611.3 105.6C612.5 106.5 616.8 107.8 624.1 109.5C631.4 111.2 636.5 113.2 639.4 115.6C642.3 118 643.7 121.4 643.7 125.8C643.7 130.6 641.7 134.6 637.7 138.1C633.7 141.5 627.9 143.2 620.1 143.2C613 143.2 607.4 141.8 603.3 138.9C599.2 136 596.5 132.1 595.3 127.2V127.2Z"
        fill="#1E3057"
      />
      <path d="M655.101 142V132H665.101V142H655.101Z" fill="#1E3057" />
      <path
        d="M677.9 142V90.1H685.8V97.5C689.6 91.8 695.1 89 702.3 89C705.4 89 708.3 89.6 710.9 90.7C713.5 91.8 715.5 93.3 716.8 95.1C718.1 96.9 719 99.1 719.5 101.6C719.8 103.2 720 106.1 720 110.1V142H711.2V110.5C711.2 106.9 710.9 104.2 710.2 102.5C709.5 100.7 708.3 99.3 706.6 98.3C704.9 97.2 702.8 96.7 700.5 96.7C696.8 96.7 693.5 97.9 690.8 100.3C688.1 102.7 686.7 107.2 686.7 113.8V142H677.9Z"
        fill="#1E3057"
      />
      <path
        d="M766.501 125.3L775.601 126.4C774.201 131.7 771.501 135.8 767.601 138.8C763.701 141.7 758.801 143.2 752.801 143.2C745.201 143.2 739.201 140.9 734.801 136.2C730.401 131.5 728.101 125 728.101 116.5C728.101 107.8 730.301 101 734.801 96.2C739.301 91.4 745.101 89 752.301 89C759.201 89 764.901 91.4 769.301 96.1C773.701 100.8 775.901 107.5 775.901 116V118.3H737.201C737.501 124 739.101 128.4 742.001 131.4C744.901 134.4 748.501 135.9 752.801 135.9C756.001 135.9 758.801 135.1 761.101 133.4C763.401 131.7 765.201 129 766.501 125.3V125.3ZM737.701 111.1H766.701C766.301 106.7 765.201 103.5 763.401 101.3C760.601 97.9 757.001 96.2 752.501 96.2C748.501 96.2 745.101 97.6 742.301 100.3C739.501 102.9 738.001 106.5 737.701 111.1Z"
        fill="#1E3057"
      />
      <path
        d="M803.3 134.1L804.6 141.9C802.1 142.4 799.9 142.7 798 142.7C794.8 142.7 792.3 142.2 790.6 141.2C788.8 140.2 787.6 138.9 786.9 137.2C786.2 135.6 785.8 132.1 785.8 126.8V97H779.4V90.2H785.8V77.4L794.5 72.1V90.2H803.3V97H794.5V127.3C794.5 129.8 794.7 131.4 795 132.1C795.3 132.8 795.8 133.4 796.5 133.8C797.2 134.2 798.2 134.4 799.5 134.4C800.5 134.4 801.7 134.3 803.3 134.1V134.1Z"
        fill="#1E3057"
      />
      <path
        d="M64.6002 69.9C71.7247 69.9 77.5002 64.1245 77.5002 57C77.5002 49.8755 71.7247 44.1 64.6002 44.1C57.4757 44.1 51.7002 49.8755 51.7002 57C51.7002 64.1245 57.4757 69.9 64.6002 69.9Z"
        fill="#1E3057"
      />
      <path
        d="M198.167 14V133.5H14.2333V14H198.167ZM200.172 0H12.2286C5.61296 0 0.200195 5.4 0.200195 12V135.5C0.200195 142.1 5.61296 147.5 12.2286 147.5H200.172C206.787 147.5 212.2 142.1 212.2 135.5V12C212.2 5.4 206.787 0 200.172 0Z"
        fill="#203057"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="14"
        y="14"
        width="185"
        height="120"
      >
        <rect x="14" y="14" width="184.2" height="119.5" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M143.7 63.8C164.521 63.8 181.4 46.9211 181.4 26.1C181.4 5.27886 164.521 -11.6 143.7 -11.6C122.879 -11.6 106 5.27886 106 26.1C106 46.9211 122.879 63.8 143.7 63.8Z"
          fill="#EC670B"
        />
        <path
          d="M23.1998 154.9C46.0644 154.9 64.5998 136.365 64.5998 113.5C64.5998 90.6354 46.0644 72.1 23.1998 72.1C0.335218 72.1 -18.2002 90.6354 -18.2002 113.5C-18.2002 136.365 0.335218 154.9 23.1998 154.9Z"
          fill="#1F75AF"
        />
        <path
          d="M23.2001 44.3C37.6147 44.3 49.3001 32.6146 49.3001 18.2C49.3001 3.78537 37.6147 -7.9 23.2001 -7.9C8.78547 -7.9 -2.8999 3.78537 -2.8999 18.2C-2.8999 32.6146 8.78547 44.3 23.2001 44.3Z"
          fill="#FBBA05"
        />
        <path
          d="M158.7 112.8C171.292 112.8 181.5 102.592 181.5 90C181.5 77.4079 171.292 67.2 158.7 67.2C146.108 67.2 135.9 77.4079 135.9 90C135.9 102.592 146.108 112.8 158.7 112.8Z"
          fill="#FBBA05"
        />
        <path
          d="M173.2 126.4C180.324 126.4 186.1 120.624 186.1 113.5C186.1 106.376 180.324 100.6 173.2 100.6C166.075 100.6 160.3 106.376 160.3 113.5C160.3 120.624 166.075 126.4 173.2 126.4Z"
          fill="#E30B18"
        />
        <path
          d="M186.4 81.4C200.814 81.4 212.5 69.7146 212.5 55.3C212.5 40.8854 200.814 29.2 186.4 29.2C171.985 29.2 160.3 40.8854 160.3 55.3C160.3 69.7146 171.985 81.4 186.4 81.4Z"
          fill="#1F75AF"
        />
        <path
          d="M64.6002 129.7C76.9714 129.7 87.0002 119.671 87.0002 107.3C87.0002 94.9288 76.9714 84.9 64.6002 84.9C52.229 84.9 42.2002 94.9288 42.2002 107.3C42.2002 119.671 52.229 129.7 64.6002 129.7Z"
          fill="#63B8E8"
        />
        <path
          d="M160.3 112.8C168 112.3 174.7 107.9 178.4 101.6C176.8 100.9 175.1 100.5 173.2 100.5C166.3 100.5 160.7 106 160.3 112.8Z"
          fill="#8B1726"
        />
        <path
          d="M42.2002 107.3C42.2002 118.6 50.5002 127.9 61.4002 129.5C63.5002 124.6 64.6002 119.2 64.6002 113.5C64.6002 103.5 61.0002 94.2 55.1002 87.1C47.5002 90.6 42.2002 98.4 42.2002 107.3V107.3Z"
          fill="#255182"
        />
        <path
          d="M160.3 55.3C160.3 56.8 160.4 58.3 160.7 59.7C171.9 54 179.9 42.8 181.2 29.6C169.2 32.1 160.3 42.7 160.3 55.3Z"
          fill="#255182"
        />
        <path
          d="M179.5 80.5C176.5 74 170.6 69.2 163.5 67.7C166.8 73.9 172.5 78.6 179.5 80.5Z"
          fill="#E19002"
        />
      </g>
      <path
        d="M194.5 137.4C192.8 137.4 191.4 138.8 191.4 140.5C191.4 142.2 192.8 143.6 194.5 143.6C196.2 143.6 197.6 142.2 197.6 140.5C197.6 138.8 196.2 137.4 194.5 137.4Z"
        fill="white"
      />
      <path
        d="M186.1 137.4C184.4 137.4 183 138.8 183 140.5C183 142.2 184.4 143.6 186.1 143.6C187.8 143.6 189.2 142.2 189.2 140.5C189.2 138.8 187.8 137.4 186.1 137.4Z"
        fill="white"
      />
      <path
        d="M177.7 137.3C176 137.3 174.6 138.7 174.6 140.4C174.6 142.1 176 143.5 177.7 143.5C179.4 143.5 180.8 142.1 180.8 140.4C180.8 138.7 179.4 137.3 177.7 137.3Z"
        fill="white"
      />
    </svg>
  </Link>
);

export default OnlineVhsLogo;
